import { useCallback, useEffect, useState } from "react";
import SnackbarUtils from "../../../../libs/SnackbarUtils";
import {
  serviceSalesTargetCPCImportFile,
  serviceSalesTargetCPCImportVerify,
} from "../../../../services/SalesTarget.service";

const initialForm = {
  file: null,
};

const useSalesDialogHook = ({
  orderId,
  isOpen,
  handleToggle,
  handleCsvUpload,
  salesInfo,
}) => {
  const [form, setForm] = useState(
    JSON.parse(JSON.stringify({ ...initialForm }))
  );
  const [errorData, setErrorData] = useState({});
  const [isVerified, setIsVerified] = useState(false);
  const [resData, setResData] = useState([]);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  useEffect(() => {
    if (isOpen) {
      setForm({ ...initialForm });
      setResData([]);
      setIsSubmitted(false);
      setIsVerified(false);
      setErrorData({});
    }
  }, [isOpen]);

  const checkFormValidation = useCallback(() => {
    const errors = { ...errorData };
    let required = ["file"];
    required.forEach((val) => {
      if (!form?.[val]) {
        errors[val] = true;
      }
    });
    Object.keys(errors).forEach((key) => {
      if (!errors[key]) {
        delete errors[key];
      }
    });

    return errors;
  }, [form, errorData]);

  const submitToServer = useCallback(() => {
    if (!isSubmitting) {
      setResData([]);
      setIsSubmitting(true);
      const fd = new FormData();
      Object.keys(form).forEach((key) => {
        fd.append(key, form[key]);
      });
      fd.append("department_id", salesInfo?.department_id);

      if (isVerified) {
        fd.append("location_id", salesInfo?.location_id);
        fd.append("fy_year", salesInfo?.fy_year);
        fd.append("quarter", salesInfo?.quarter);
      }
      let req = isVerified
        ? serviceSalesTargetCPCImportFile
        : serviceSalesTargetCPCImportVerify;
      req(fd).then((res) => {
        if (!res.error) {
          if (isVerified) {
            handleCsvUpload();
            handleToggle();
            SnackbarUtils.success("Data Imported Successfully");
          }
          if (res.data.length === 0) {
            setIsVerified((e) => !e);
          }
          setIsSubmitted(true);
          setResData(res.data);
        }
        setIsSubmitting(false);
      });
    }
  }, [
    form,
    isSubmitting,
    setIsSubmitting,
    orderId,
    handleToggle,
    setIsSubmitting,
    setResData,
    handleCsvUpload,
    isVerified,
    setIsVerified,
    salesInfo,
  ]);

  const handleSubmit = useCallback(
    async (e) => {
      e.preventDefault();
      const errors = checkFormValidation();
      if (Object.keys(errors).length > 0) {
        setErrorData(errors);
        return true;
      }
      submitToServer();
    },
    [checkFormValidation, setErrorData, form, submitToServer, salesInfo]
  );

  const removeError = useCallback(
    (title) => {
      const temp = JSON.parse(JSON.stringify(errorData));
      temp[title] = false;
      setErrorData(temp);
    },
    [setErrorData, errorData]
  );

  const changeTextData = useCallback(
    (text, fieldName) => {
      let shouldRemoveError = true;
      const t = { ...form };
      t[fieldName] = text;
      setForm(t);
      shouldRemoveError && removeError(fieldName);
      setIsVerified(false);
    },
    [removeError, form, setForm, setIsVerified]
  );

  const onBlurHandler = useCallback(
    (type) => {
      if (form?.[type]) {
        changeTextData(form?.[type].trim(), type);
      }
    },
    [changeTextData]
  );

  return {
    form,
    changeTextData,
    onBlurHandler,
    removeError,
    handleSubmit,
    errorData,
    isSubmitting,
    resData,
    isSubmitted,
    isVerified,
  };
};

export default useSalesDialogHook;
