import React, { useMemo } from "react";
import useDocumentForm from "./DocumentForm.hook";
import styles from "./Style.module.css";
import { removeUnderScore } from "../../../helper/helper";
import { ButtonBase, CircularProgress, Tooltip } from "@material-ui/core";
import MultiFile from "../../ClaimsManagement/ClaimsDetail/components/ClaimTaxCard/component/FileComponent/FileMultiComponent.component";
import MultiFieldIncludeForm from "./component/MultiField/MultiFieldIncludes.component";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";
import constants from "../../../config/constants";

function DocumentForm() {
  const {
    candidateInfo,
    printUpload,
    getUrlfromFile,
    singleUpload,
    childRef,
    handleDraft,
    handleSubmit,
    isSubmitting,
    printError,
    errorData,
    handleDownload,
  } = useDocumentForm({});

  const memoizedPrintContent = useMemo(() => {
    return printUpload?.map((val, index) => (
      <div key={`printU_${index}`} className={styles.printWrap}>
        <div className={styles.subHeading}>
          {removeUnderScore(val?.document)}
          <Tooltip
            title={`You can download the printable form through the "Download" button and download the sample filled form through the "View Sample File" button .`}
            placement="top"
          >
            <InfoOutlinedIcon
              style={{ color: "#2896E9", fontSize: ".975rem" }}
            />
          </Tooltip>
        </div>
        <div className={styles.downWrap}>
          <div className={styles.btnWrap}>
            <ButtonBase
              className={styles.editSuccess}
              onClick={() => handleDownload(val?.sample_template)}
            >
              DOWNLOAD
            </ButtonBase>
            <a
              style={{ color: "inherit" }}
              key={`Image_sample_${index}`}
              href={val?.sample_example}
              target="_blank"
              rel="noopener noreferrer"
            >
              <ButtonBase className={styles.editSuccess}>
                VIEW SAMPLE FILE
              </ButtonBase>
            </a>
          </div>
          <div style={{ width: "100%", flex: "2" }}>
            {val?.status === "APPROVED" ? (
              <div>
                <a
                  key={`uploader_url_${index}`}
                  href={constants?.UPLOADER_URL + val?.files}
                  target="_blank"
                  className={styles.achorImg}
                >
                  view
                </a>
              </div>
            ) : (
              <MultiFile
                key={`uploaderDef_${index}`}
                max_size={10 * 1024 * 1024}
                type={["pdf", "jpeg", "doc", "docx", "jpg", "png"]}
                fullWidth={true}
                name="files"
                label="Upload File"
                accept={"application/pdf,application/msword,image/*"}
                value={val?.files || null}
                placeholder={"Upload File"}
                onChange={(file) => {
                  getUrlfromFile(file, "files", index, "print");
                }}
                error={printError?.[`print_${index}`]}
                deleteImage={(file) => {
                  // deleteImage(file, "file");
                }}
                removeHideBtn
              />
            )}
          </div>
        </div>
        {printUpload?.length - 1 !== index && (
          <div className={styles.horizontalLine}></div>
        )}
      </div>
    ));
  }, [
    printUpload,
    getUrlfromFile,
    removeUnderScore,
    printError,
    handleDownload,
  ]);

  const memoizedSingleContent = useMemo(() => {
    return (
      singleUpload?.length > 0 &&
      singleUpload?.map((val, index) => (
        <div key={`printSingle_${index}`} className={styles.printWrap}>
          <div className={styles.subHeading}>
            {removeUnderScore(val?.document)}
          </div>
          <div style={{ display: "flex", flex: "1" }}>
            <div style={{ width: "100%", flex: "2" }}>
              {val?.status === "APPROVED" ? (
                <div>
                  <a
                    key={`uploader_url_${index}`}
                    href={constants?.UPLOADER_URL + val?.files}
                    target="_blank"
                    className={styles.achorImg}
                  >
                    view
                  </a>
                </div>
              ) : (
                <MultiFile
                  key={`uploaderDef_${index}`}
                  max_size={10 * 1024 * 1024}
                  type={["pdf", "jpeg", "doc", "docx", "jpg", "png"]}
                  fullWidth={true}
                  name="files"
                  label="Upload File"
                  accept={"application/pdf,application/msword,image/*"}
                  value={val?.files || null}
                  placeholder={"Upload File"}
                  onChange={(file) => {
                    getUrlfromFile(file, "files", index, "single");
                  }}
                  error={errorData?.[`single_${index}`]}
                  deleteImage={(file) => {
                    // deleteImage(file, "file");
                  }}
                  removeHideBtn
                />
              )}
            </div>
          </div>
          {singleUpload?.length - 1 !== index && (
            <div className={styles.horizontalLine}></div>
          )}
        </div>
      ))
    );
  }, [singleUpload, getUrlfromFile, removeUnderScore, errorData]);

  return (
    <div className={styles.parentCont}>
      <div>
        <div className={styles.logoImg}>
          <img
            src={require("../../../assets/img/login logo@2x.png")}
            className={styles.sky}
          />
        </div>
        <div className={styles.loginSignupText}>
          <h1 className={styles.headingText}>
            Pre-Onboarding Documents Submission Form
          </h1>
        </div>
      </div>
      <div className={styles.candidateInfo}>
        <div className={styles.plainPaper}>
          <div className={styles.container}>
            <div>
              <img
                src={candidateInfo?.candidate?.image}
                alt="image"
                className={styles.profileImg}
              />
            </div>
            <div className={styles.lowerWrap}>
              <div>{candidateInfo?.candidate?.name}</div>
              <div>{candidateInfo?.candidate?.email}</div>
              <div>{candidateInfo?.candidate?.contact}</div>
            </div>
          </div>
        </div>
      </div>
      <div className={styles.candidateInfo2}>
        <div className={styles.plainPaper}>
          <div className={styles.heading}>Direct Upload Documents</div>
          <br />
          <span className={styles.spanWrap}>
            Please upload the requested documents in there respective fields.
          </span>
          <MultiFieldIncludeForm ref={childRef} />
          {memoizedSingleContent}
          <div className={styles.heading}></div>
        </div>
      </div>
      {printUpload?.length > 0 && (
        <div className={styles.candidateInfo2}>
          <div className={styles.plainPaper}>
            <div className={styles.heading}>
              Download-Print-Upload Documents
            </div>
            <br />
            <span className={styles.spanWrap}>
              Please download the sample template first, print it, complete all
              the requirements and upload the document in there respective
              field.
            </span>
            {memoizedPrintContent}
            <div className={styles.heading}></div>
          </div>
        </div>
      )}
      <div className={styles.btnWrapper}>
        {candidateInfo?.status === "INITIATED" ||
        candidateInfo?.status === "DRAFTED" ? (
          <ButtonBase
            className={styles.editSuccess}
            disabled={isSubmitting}
            onClick={handleDraft}
          >
            {isSubmitting ? (
              <CircularProgress color="success" size="20px" />
            ) : (
              "SAVE AS DRAFT"
            )}
          </ButtonBase>
        ) : (
          <div></div>
        )}

        <ButtonBase
          // disabled={isSubmitting}
          aria-haspopup="true"
          onClick={handleSubmit}
          className={"createBtn"}
        >
          {isSubmitting ? (
            <CircularProgress color="success" size="20px" />
          ) : (
            "Submit"
          )}
        </ButtonBase>
      </div>
    </div>
  );
}

export default DocumentForm;
