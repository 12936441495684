import { useDispatch, useSelector } from "react-redux";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import {
  actionFetchSalesTarget,
  actionSetPageSalesTargetRequests,
} from "../../actions/SalesTarget.action";
import { serviceGetList } from "../../services/Common.service";
import SnackbarUtils from "../../libs/SnackbarUtils";
import {
  serviceGetSalesTargetStatus,
  serviceSalesTargetSend,
} from "../../services/SalesTarget.service";

const initialForm = {
  location_id: "",
  department_id: "66600f565a24d986078325a0",
  fy_year: "",
  quarter: "Q1",
};

const useSalesTarget = ({}) => {
  const [form, setForm] = useState({ ...initialForm });
  const [errorData, setErrorData] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [statusEnable, setStatusEnable] = useState(false);
  const [isConfirm, setIsConfirm] = useState(false);
  const [isSidePanel, setSidePanel] = useState(false);
  const [listData, setListData] = useState({
    LOCATIONS: [],
    DEPARTMENTS: [],
    FY_YEAR: [],
  });
  const dispatch = useDispatch();
  const isMountRef = useRef(false);

  useEffect(() => {
    serviceGetList(["LOCATIONS", "FY_YEAR", "DEPARTMENTS"]).then((res) => {
      if (!res.error) {
        setListData(res.data);
      }
    });
  }, []);

  // useEffect(() => {
  //   const storedYr = sessionStorage.getItem("year");
  //   const storedType = sessionStorage.getItem("typeClaim");
  //   if (storedYr) {
  //     setWareHouseId(storedYr);
  //   }
  //   if (storedType) {
  //     setType(storedType);
  //   }
  // }, []);

  const {
    sorting_data: sortingData,
    is_fetching: isFetching,
    query,
    query_data: queryData,
  } = useSelector((state) => state.sales);

  const enableUploader = useMemo(() => {
    return Object.values(form)?.every((value) => value !== "");
  }, [form]);

  const resetData = useCallback(
    (sort = {}) => {
      if (enableUploader) {
        dispatch(
          actionFetchSalesTarget(
            1,
            { sortingData, ...sort },
            {
              query: isMountRef.current ? query : null,
              query_data: isMountRef.current ? queryData : null,
              ...form,
            }
          )
        );
        const req = serviceGetSalesTargetStatus({ ...form });
        req.then((res) => {
          const data = res?.data?.is_sent;
          setStatusEnable(data);
        });
      }
      isMountRef.current = true;
    },
    [query, queryData, form, sortingData, , enableUploader, statusEnable]
  );

  useEffect(() => {
    resetData();
  }, [form]);

  const removeError = useCallback(
    (title) => {
      const temp = JSON.parse(JSON.stringify(errorData));
      temp[title] = false;
      setErrorData(temp);
    },
    [setErrorData, errorData]
  );

  const changeTextData = useCallback(
    (text, fieldName) => {
      // LogUtils.log(text, fieldName);
      let shouldRemoveError = true;
      const t = { ...form };

      t[fieldName] = text;
      setForm(t);
      sessionStorage.setItem("sales_form", t);
      shouldRemoveError && removeError(fieldName);
    },
    [removeError, form, setForm]
  );

  const checkFormValidation = useCallback(() => {
    const errors = { ...errorData };
    let required = ["location_id", "department_id", "fy_year", "quarter"];

    required.forEach((val) => {
      if (
        !form?.[val] ||
        (Array.isArray(form?.[val]) && form?.[val].length === 0)
      ) {
        errors[val] = true;
      }
    });
    Object.keys(errors).forEach((key) => {
      if (!errors[key]) {
        delete errors[key];
      }
    });
    return errors;
  }, [form, errorData]);

  const handleSubmit = useCallback(async () => {
    const errors = checkFormValidation();
    if (Object.keys(errors).length > 0) {
      setErrorData(errors);
      return true;
    }
    // submitToServer();
  }, [checkFormValidation, setErrorData, form]);

  const handlePageChange = useCallback((type) => {
    console.log("_handlePageChange", type);
    dispatch(actionSetPageSalesTargetRequests(type));
  }, []);

  const queryFilter = useCallback(
    (key, value) => {
      resetData(
        {},
        {
          query: key == "SEARCH_TEXT" ? value : query,
          query_data: key == "FILTER_DATA" ? value : queryData,
        }
      );
    },
    [query, queryData, resetData]
  );

  const handleFilterDataChange = useCallback(
    (value) => {
      console.log("_handleFilterDataChange", value);
      queryFilter("FILTER_DATA", value);
    },
    [queryFilter]
  );

  const handleSearchValueChange = useCallback(
    (value) => {
      console.log("_handleSearchValueChange", value);
      queryFilter("SEARCH_TEXT", value);
    },
    [queryFilter]
  );

  const handleSortOrderChange = useCallback(
    (row, order) => {
      console.log(`handleSortOrderChange key:${row} order: ${order}`);
      dispatch(actionSetPageSalesTargetRequests(1));
      resetData({ row, order }, {});
    },
    [resetData]
  );

  const handleRowSize = (page) => {
    console.log(page);
  };

  const handleSideToggle = useCallback(
    (data) => {
      setSidePanel((e) => !e);
    },
    [setSidePanel]
  );

  const handleConfirmToggle = useCallback(
    (data) => {
      setIsConfirm((e) => !e);
    },
    [setIsConfirm]
  );
  const handleConfirm = useCallback(() => {
    if (enableUploader) {
      if (!isSubmitting) {
        setIsSubmitting(true);
        serviceSalesTargetSend({
          ...form,
        }).then((res) => {
          if (!res.error) {
            SnackbarUtils.success("Confirmed and Send Successfully");
            setIsConfirm(false);
            // historyUtils.goBack();
          } else {
            SnackbarUtils.error(res?.message);
          }
          setIsSubmitting(false);
        });
      }
    }
  }, [setIsSubmitting, isSubmitting, form]);

  const configFilter = useMemo(() => {
    return [
      {
        label: "Location",
        name: "location_id",
        type: "selectObject",
        custom: { extract: { id: "id", title: "name" } },
        fields: listData?.LOCATIONS,
      },
    ];
  }, [listData]);
  return {
    handlePageChange,
    handleFilterDataChange,
    handleSearchValueChange,
    handleRowSize,
    handleSortOrderChange,
    handleSideToggle,
    isSidePanel,
    configFilter,
    listData,
    form,
    errorData,
    changeTextData,
    isSubmitting,
    resetData,
    enableUploader,
    handleConfirmToggle,
    isConfirm,
    handleConfirm,
    statusEnable,
  };
};

export default useSalesTarget;
