import { postRequest, formDataRequest } from "../libs/AxiosService.util";

export async function serviceCreateSalesTarget(params) {
  return await formDataRequest("cr/budget/edit/versions/create", params);
}

export async function serviceUpdateSalesTarget(params) {
  return await postRequest("cr/update", params);
}

export async function serviceDeleteSalesTarget(params) {
  return await postRequest("cr/delete", params);
}

export async function serviceGetSalesTarget(params) {
  return await postRequest("sales/target", params);
}
export async function serviceGetSalesTargetStatus(params) {
  return await postRequest("sales/target/status", params);
}
export async function serviceSalesTargetDetail(params) {
  return await postRequest("cr/budget/detail", params);
}
export async function serviceSalesTargetSend(params) {
  return await postRequest("sales/target/send", params);
}
export async function serviceSalesTargetCPCImportVerify(params) {
  return await formDataRequest("sales/target/import/verify", params);
}
export async function serviceSalesTargetCPCImportFile(params) {
  return await formDataRequest("sales/target/import", params);
}
