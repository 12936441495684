import React, { useCallback, useMemo } from "react";
import { ButtonBase, CircularProgress, MenuItem } from "@material-ui/core";
import PageBox from "../../components/PageBox/PageBox.component";
import styles from "./Style.module.css";
import CustomSelectField from "../../components/FormFields/SelectField/SelectField.component";
import useApprovalRoute from "./ApprovalRoute.hook";
import { Stepper, Step, StepLabel } from "@material-ui/core";
import { removeUnderScore } from "../../helper/helper";

const ApprovalRoute = ({}) => {
  const {
    listData,
    type,
    setType,
    locationId,
    setLocationId,
    data,
    claimType,
    renderList,
    loading,
  } = useApprovalRoute({});

  const renderLocation = useMemo(() => {
    return (
      <CustomSelectField
        label={"Location"}
        value={locationId}
        handleChange={(value) => {
          setLocationId(value);
        }}
      >
        {listData?.LOCATIONS?.map((dT) => {
          return (
            <MenuItem value={dT?.id} key={dT?.id}>
              {dT?.name}
            </MenuItem>
          );
        })}
      </CustomSelectField>
    );
  }, [listData?.LOCATIONS, locationId]);

  const renderDropDownType = useMemo(() => {
    return (
      <CustomSelectField
        label={"Select Claim"}
        value={type}
        handleChange={(value) => {
          setType(value);
        }}
      >
        <MenuItem value={"ALL"}>ALL</MenuItem>
        {claimType?.map((dT, index) => {
          return (
            <MenuItem value={dT} key={`type_${index}`}>
              {removeUnderScore(dT)}
            </MenuItem>
          );
        })}
      </CustomSelectField>
    );
  }, [type, setType]);

  const transformData = (trans) => {
    return trans?.map((item) => {
      const roleKey = Object.keys(item)[0];
      const roleData = item[roleKey];
      return {
        label: roleKey,
        name: roleData.name,
        emp_code: roleData.emp_code,
      };
    });
  };

  return (
    <div>
      <PageBox>
        <div className={styles.headerContainer}>
          <div>
            <span className={styles.title}>Approval Routes</span>
            <div className={styles.newLine} />
          </div>
        </div>

        <div className={styles.yearFlex}>
          <div className={styles.drop}>{renderLocation}</div>
          <div className={styles.down}>{renderDropDownType}</div>
          <div className={styles.down21}>
            <ButtonBase
              className={styles.download}
              onClick={renderList}
              disabled={loading}
            >
              {loading ? (
                <CircularProgress color="success" size="20px" />
              ) : (
                "CHECK ROUTES"
              )}
            </ButtonBase>
          </div>
        </div>
      </PageBox>
      {data && (
        <div style={{ marginTop: "15px" }}>
          <PageBox>
            {claimType?.map((val, index) => {
              return (
                data[val] && (
                  <div key={`step_${index}`}>
                    <div className={styles.titleWrap}>
                      {removeUnderScore(val)}
                    </div>
                    <Stepper alternativeLabel>
                      <Step key={`emp_d${index}`} active={true}>
                        <StepLabel
                          StepIconComponent={() => (
                            <div className={styles.stepperStep}>
                              <div className={styles.innerStep}></div>
                            </div>
                          )}
                        >
                          <div>
                            <span className={styles.title}>EMPLOYEE</span>
                          </div>
                        </StepLabel>
                      </Step>
                      {transformData(data[val])?.map((step, index) => (
                        <Step key={`stepper_${index}`} active={true}>
                          <StepLabel
                            StepIconComponent={() => (
                              <div className={styles.stepperStep}>
                                <div className={styles.innerStep}></div>
                              </div>
                            )}
                          >
                            <div>
                              <span className={styles.title}>
                                {removeUnderScore(step?.label)}
                              </span>
                              <br />
                              <span className={styles.title}>{step?.name}</span>
                              <br />
                              <span className={styles.title21}>
                                {step?.emp_code}
                              </span>
                              {step?.label === "EXCEPTION_APPROVER" && (
                                <div className={styles.exception}>
                                  (If exception)
                                </div>
                              )
                              }
                              {
                                step?.label === "HOD" &&  <div className={styles.exceptiontext}>
                                (Non paper based)
                                </div>
                              }
                            </div>
                          </StepLabel>
                        </Step>
                      ))}
                    </Stepper>
                  </div>
                )
              );
            })}
          </PageBox>
        </div>
      )}
    </div>
  );
};

export default ApprovalRoute;
