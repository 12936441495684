import { useCallback, useEffect, useState } from "react";
import { serviceGetList } from "../../services/Common.service";
import { serviceGetClaimRoute } from "../../services/Claims.service";
import SnackbarUtils from "../../libs/SnackbarUtils";

const claimType = [
  "MARRAIGE",
  "CAR",
  "MOBILE",
  "PHC",
  "LOCAL_TRAVEL",
  "INTERVIEW",
  "RELOCATION",
  "TRAVEL",
  "FOREIGN_TRAVEL",
  "MISCELLANEOUS",
  "TAX_REBATE",
  "LOAN",
  "TRAVEL_PLANNER",
  "IMPREST_TRAVEL_FOREIGN",
  "IMPREST_TRAVEL_DOMESTIC",
  "IMPREST_OTHER",
  "OLR",
];
const useApprovalRoute = ({}) => {
  const [type, setType] = useState("");
  const [locationId, setLocationId] = useState("");
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState(null);
  const [listData, setListData] = useState({
    LOCATIONS: [],
  });
  useEffect(() => {
    serviceGetList(["LOCATIONS"]).then((res) => {
      if (!res.error) {
        setListData(res.data);
      }
    });
  }, []);

  const renderList = useCallback(() => {
    if (locationId && type) {
      setLoading(true);
      const req = serviceGetClaimRoute({
        locationId: locationId,
        claimType: type,
      });
      req.then((res) => {
        if (!res?.error) {
          setData(res?.data);
        }
        setLoading(false);
      });
    } else {
      SnackbarUtils.error("Please select the location and Claim type");
    }
  }, [locationId, type]);

  return {
    listData,
    type,
    setType,
    locationId,
    setLocationId,
    data,
    claimType,
    loading,
    renderList,
  };
};

export default useApprovalRoute;
